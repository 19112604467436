import React, { useEffect, useState } from 'react';
// Layouts
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// PAF Header
import TextField from "../../components/TextField";
import TextArea from "../../components/TextArea";
import Checkbox from "../../components/Checkbox";
import Tooltip from "../../components/Tooltip";
import Dropdown from "../../components/Dropdown";
import FullName from "../../PAF/Components/FullName";
// import { useHistory } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import { COUNTRIES } from '../../common/constant';
import PreviewFormFooter from './PreviewFormFooter';
import PreviewFormHeader from './PreviewFormHeader';
import MultiSelect from '../../components/MultiSelect';
import service from '../../common/service';
import { useParams } from 'react-router-dom';


const DirectorInfo = (props) => {
    const pafType = useSelector(e => e.SharedPafType );
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const { DirectorData, index, onChange, item, BioValidation, disabled,indieTopcredit } = props
    let COUNTRIES_LIST = COUNTRIES
    const [BioLength, setBioLength] = useState(0)

    const dropOption = {
        preferredPronouns: ['He/Him', 'She/Her', 'They/Them', 'Other'],
        directorGenderExpression: ['Woman', 'Man', 'Non-Binary or Gender Non-conforming', 'Intersex', '2Spirit', 'Other', 'Decline to State'],
        directorIdentifyTransgender: ['Yes', 'No', 'Other', 'Decline to State'],
        sexualOrientation: ['Heterosexual', 'Gay or Lesbian', 'Bisexual and/or Pansexual', 'Other', 'Decline to State'],
        personWithDisability: [{ label: 'Yes, Cognitive', value: 'Yes, Cognitive' },
        { label: 'Yes, Emotional', value: 'Yes, Emotional' },
        { label: 'Yes, Hearing', value: 'Yes, Hearing' },
        { label: 'Yes, Visual', value: 'Yes, Visual' },
        { label: 'Yes, Sensory (non-vision/hearing)', value: 'Yes, Sensory (non-vision/hearing)' },
        { label: 'Yes, Mental', value: 'Yes, Mental' },
        { label: 'Yes, Physical', value: 'Yes, Physical' },
        { label: 'No', value: 'No' },
        { label: 'Other', value: 'Other' },
        { label: 'Decline to State', value: 'Decline to State' }]
    }

    const { asian, africanAmerican, whiteEuropean, Hispaniclati, middleEastern, nativeAmerican, pacificIslanderorHawaiian, biRacialMultiRacial, other, declineToState,AmericanIndian, EastAsian, SouthAsian, NativeHawaiian, SoutheastAsian } = DirectorData.creditDetails
    const [ethnicOrigin] = useState([{ name: "africanAmerican", value: "Black, African American or of African descent", isChecked: africanAmerican ? africanAmerican : false },
    // { name: 'AmericanIndian', value: 'American Indian, Alaskan Native, or Indigenous', isChecked: AmericanIndian ? AmericanIndian : false },
    { name: "nativeAmerican", value: "Native American or First Nations or Indigenous", isChecked: nativeAmerican ? nativeAmerican : false },
    { name: 'EastAsian', value: 'East Asian', isChecked: EastAsian ? EastAsian : false },
    { name: 'SouthAsian', value: 'South Asian', isChecked: SouthAsian ? SouthAsian : false },
    // { name: 'NativeHawaiian', value: 'Native Hawaiian', isChecked: NativeHawaiian ? NativeHawaiian : false },
    { name: 'SoutheastAsian', value: 'Southeast Asian', isChecked: SoutheastAsian ? SoutheastAsian : false },
    { name: "whiteEuropean", value: "White, European, or of European descent", isChecked: whiteEuropean ? whiteEuropean : false },
    { name: 'Hispaniclati', value: 'Hispanic, Latino/a, Latinx, Latine', isChecked: Hispaniclati ? Hispaniclati : false },
    { name: "middleEastern", value: "Middle Eastern", isChecked: middleEastern ? middleEastern : false },
    { name: "pacificIslanderorHawaiian", value: "Pacific Islander and/or Native Hawaiian", isChecked: pacificIslanderorHawaiian ? pacificIslanderorHawaiian : false },
    // { name: "biRacialMultiRacial", value: "Bi-racial or Multi-Racial", isChecked: biRacialMultiRacial ? biRacialMultiRacial : false },
    { name: "other", value: "Other", isChecked: other ? other : false },
    { name: "declineToState", value: "Decline to State", isChecked: declineToState ? declineToState : false },])
    useEffect(() => {
        if (DirectorData.creditDetails?.bio) {
            let len = DirectorData.creditDetails?.bio?.match(/\S+/g)
            len = len?.filter(_ => _ !== ' ');
            setBioLength(len?.length || 0)
        } else{
            setBioLength(0)
        }

    }, [DirectorData.creditDetails.bio])
    return (
        <div >
            <div className="sd_flex sd_aligncenter associate_list">
                <FullName credit={DirectorData} item={item} disabled={true} values={DirectorData} title={pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director ${index + 1}`}  />
            </div>
            <div className="sd_flex sd_aligncenter associate_list ">
                <div className="sd_container_990">
                    <div className="sd_flex fullname_div sd_justbetween">
                        <TextField disabled={disabled} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.preferredPronouns} name='preferredPronouns' label="Preferred Pronouns" required="false" />
                        <TextField disabled={disabled} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.englishPhoneticProdununciation} name="englishPhoneticProdununciation" label="English Phonetic Pronunciation" required="false" />
                        <Dropdown disable={disabled} credit={DirectorData} item={item} label="First Feature?" name='firstFeature' value={DirectorData.creditDetails?.firstFeature} onChange={onChange} uppercase options={["Yes", "No"]} DefaultOption={'Select From Below'} />
                    </div>
                </div>

                {/* <FullName /> */}
            </div>
            <div className="sd_container_990">
                <div class="field_row_inner icon_label">
                    <label className='sd_flex sd_aligncenter'>BIO</label>
                    <p className='mb_10'>{(pafType === 'New Frontier') ? "Please provide a short bio for each director, lead artist, or individual listed as the top credit. All bios will be edited before being published on our website, mobile app, and print publications. Each bio must not exceed 50 words; however, the combined total for ALL bios must not exceed 100 words." : (pafType === 'Indie Series/Special Events') ? `Please provide a short bio for each individual listed as the top credit for your project. All bios will be edited before being published. Each bio must not exceed 50 words; however, the combined total for ALL bios must not exceed 100 words.` : `Please provide a short bio for each director. All bios will be edited before being published. Each bio must not exceed 50 words; however, the combined total for ALL bios must not exceed 100 words.`}</p>
                    <div class="field_input relative">
                        <TextArea disabled={disabled} height='225px' credit={DirectorData} item={item} handleChange={onChange} name='bio' value={DirectorData.creditDetails?.bio} />
                        <span style={{ color: 'red' }}>{BioValidation ? 'More than 50 Word is not allowed' : ''}</span>
                    </div>
                    <div className="textarea_word sd_flex sd_aligncenter">
                        <label className='mb_0'>Word Count</label>
                        <span>{BioLength}/50</span>
                    </div>
                </div>
            </div>
            <div className="for_padding team_info_section sd_container_990 mb_0 pb_0">
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="company" handleChange={onChange} value={DirectorData.creditDetails?.company} label="Company" uppercase />
                </div>
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="email" handleChange={onChange} value={DirectorData.creditDetails?.email} label="Email" uppercase />
                </div>
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="address1" handleChange={onChange} value={DirectorData.creditDetails?.address1} label="Address 1" uppercase />
                </div>
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="address2" handleChange={onChange} value={DirectorData.creditDetails?.address2} label="Address 2" uppercase />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="city" handleChange={onChange} value={DirectorData.creditDetails?.city} label="City" uppercase />
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="state" handleChange={onChange} value={DirectorData.creditDetails?.state} label="State/Province" uppercase />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="zipCode" handleChange={onChange} value={DirectorData.creditDetails?.zipCode} label="Zip Code" uppercase />
                    <Dropdown disable={disabled} label="Country" name='country' credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.country} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="phoneNumber" handleChange={onChange} value={DirectorData.creditDetails?.phoneNumber} label="Phone Number" uppercase />
                    <Dropdown disable={disabled} credit={DirectorData} item={item} label="Phone Type" name='type' onChange={onChange} uppercase value={DirectorData.creditDetails?.type} options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown disable={disabled} credit={DirectorData} item={item} label={`What is ${pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director ${index + 1}`}'s gender expression?`} name='directorGenderExpression' onChange={onChange} value={DirectorData.creditDetails?.directorGenderExpression} uppercase options={dropOption.directorGenderExpression} DefaultOption={'Select From Below'} />
                    <Dropdown disable={disabled} credit={DirectorData} item={item} label={`Does ${pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director ${index + 1}`} identify as Transgender?`} name='directorIdentifyTransgender' onChange={onChange} value={DirectorData.creditDetails?.directorIdentifyTransgender} uppercase options={dropOption.directorIdentifyTransgender} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown disable={disabled} credit={DirectorData} item={item} label={`How would ${pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director ${index + 1}`} define their sexual orientation?`} name='sexualOrientation' onChange={onChange} value={DirectorData.creditDetails?.sexualOrientation} uppercase options={dropOption.sexualOrientation} DefaultOption={'Select From Below'} />
                    <MultiSelect disable={disabled} credit={DirectorData} item={item} label={`Does ${pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director ${index + 1}`} identify as a person with a disability?`} name='personWithDisability' onChange={onChange} value={DirectorData.creditDetails?.personWithDisability} uppercase options={dropOption.personWithDisability} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    {DirectorData?.creditDetails?.personWithDisability && DirectorData?.creditDetails?.personWithDisability.length > 0 && DirectorData.creditDetails?.personWithDisability.some(_ => _.value === 'Other / Prefer to describe') && <TextField disabled={true} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.personWithDisabilityOther} name="personWithDisabilityOther" label="Other / Prefer to describe" />}
                    <Dropdown disable={disabled} credit={DirectorData} item={item} label={`In which country is  ${pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director ${index + 1}`} born?`} name='directorBornCountry' onChange={onChange} value={DirectorData.creditDetails?.directorBornCountry} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
                </div>
                <div className="no_border">
                    <div className="question">
                        <div className="question_label">
                            <p>What is Director{pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director${index + 1}`}’s race and/or ethnic origin?</p>
                            <span className="sd_textuppercase">Check all that apply.</span>
                        </div>
                        <div className="check_col_2 sd_flex sd_flexwrap share_check">
                            {
                                ethnicOrigin.length > 0 && ethnicOrigin.map((Origin, i) => {
                                    // if (Origin.name === 'asian') Origin.isChecked = asian
                                    if (Origin.name === 'africanAmerican') Origin.isChecked = africanAmerican
                                    if (Origin.name === 'whiteEuropean') Origin.isChecked = whiteEuropean
                                    if (Origin.name === 'Hispaniclati') Origin.isChecked = Hispaniclati
                                    if (Origin.name === 'middleEastern') Origin.isChecked = middleEastern
                                    if (Origin.name === 'nativeAmerican') Origin.isChecked = nativeAmerican
                                    if (Origin.name === 'pacificIslanderorHawaiian') Origin.isChecked = pacificIslanderorHawaiian
                                    // if (Origin.name === 'biRacialMultiRacial') Origin.isChecked = biRacialMultiRacial
                                    // if (Origin.name === 'AmericanIndian') Origin.isChecked = AmericanIndian
                                    if (Origin.name === 'EastAsian') Origin.isChecked = EastAsian
                                    if (Origin.name === 'SouthAsian') Origin.isChecked = SouthAsian
                                    // if (Origin.name === 'NativeHawaiian') Origin.isChecked = NativeHawaiian
                                    if (Origin.name === 'SoutheastAsian') Origin.isChecked = SoutheastAsian
                                    if (Origin.name === 'other') Origin.isChecked = other
                                    if (Origin.name === 'declineToState') Origin.isChecked = declineToState

                                    return (
                                        <Checkbox credit={DirectorData} item={item} type="checkbox" onChange={() => ''} name={Origin?.name} label={Origin?.value} checked={Origin.isChecked} id={`Origin_${index}${i}`} />
                                    )
                                })
                            }
                            {/* <TextField disabled={disabled} credit={DirectorData} item={item} type="text" /> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="field_row_inner mb_0">
                        <label className="sd_textuppercase">
                        {(pafType === 'New Frontier') ? `By providing Instagram, Twitter, and TikTok information, the Sundance Film Festival will consider it public and may tag our posts with this information.` : `By providing Instagram, Facebook, X (formerly Twitter), and TikTok information, the Sundance Film Festival will consider it public and may tag our posts with this information.`}
                        </label>
                    </div>
                    <div className="sd_flex fullname_div two_field">
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" placeholder='https://www.instagram.com/mymovie' name="instagram" handleChange={onChange} value={DirectorData.creditDetails?.instagram} label="Instagram" uppercase />
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" placeholder='https://www.twitter.com/mymovie' name="twitter" handleChange={onChange} value={DirectorData.creditDetails?.twitter} label='X (Formerly "Twitter")' uppercase />
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" placeholder='mymovie' name="tiktok" handleChange={onChange} value={DirectorData.creditDetails?.tiktok} label="Tiktok" uppercase />
                    </div>
                </div>
            </div>
        </div>
    )
}
const Step5 = () => {
    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const pafType = useSelector(e => e.SharedPafType);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [formFive, setFormFive] = useState({
        credits: ProjectInfo.credits,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })

    const [BioValidation] = useState(false)

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])

    useEffect(() => {
        formFive.credits.forEach(item => {
            if (item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formFive.indieTopcredit?.identifier : 'director')) {
                item.creditType.forEach((credit) => {
                    credit.creditDetails = {
                        englishPhoneticProdununciation: credit.creditDetails.englishPhoneticProdununciation ? credit.creditDetails.englishPhoneticProdununciation : '',
                        preferredPronouns: credit.creditDetails.preferredPronouns ? credit.creditDetails.preferredPronouns : '',
                        firstFeature: credit.creditDetails.firstFeature ? credit.creditDetails.firstFeature : '',
                        bio: credit.creditDetails.bio ? credit.creditDetails.bio : '',
                        company: credit.creditDetails.company ? credit.creditDetails.company : '',
                        email: credit.creditDetails.email ? credit.creditDetails.email : '',
                        address1: credit.creditDetails.address1 ? credit.creditDetails.address1 : '',
                        address2: credit.creditDetails.address2 ? credit.creditDetails.address2 : '',
                        city: credit.creditDetails.city ? credit.creditDetails.city : '',
                        state: credit.creditDetails.state ? credit.creditDetails.state : '',
                        zipCode: credit.creditDetails.zipCode ? credit.creditDetails.zipCode : '',
                        country: credit.creditDetails.country ? credit.creditDetails.country : '',
                        phoneNumber: credit.creditDetails.phoneNumber ? credit.creditDetails.phoneNumber : '',
                        type: credit.creditDetails.type ? credit.creditDetails.type : '',
                        directorGenderExpression: credit.creditDetails.directorGenderExpression ? credit.creditDetails.directorGenderExpression : '',
                        directorIdentifyTransgender: credit.creditDetails.directorIdentifyTransgender ? credit.creditDetails.directorIdentifyTransgender : '',
                        sexualOrientation: credit.creditDetails.sexualOrientation ? credit.creditDetails.sexualOrientation : '',
                        personWithDisability: credit.creditDetails.personWithDisability ? credit.creditDetails.personWithDisability : '',
                        directorBornCountry: credit.creditDetails.directorBornCountry ? credit.creditDetails.directorBornCountry : '',
                        // asian: credit.creditDetails.asian === undefined ? false : credit.creditDetails.asian,
                        africanAmerican: credit.creditDetails.africanAmerican === undefined ? false : credit.creditDetails.africanAmerican,
                        whiteEuropean: credit.creditDetails.whiteEuropean === undefined ? false : credit.creditDetails.whiteEuropean,
                        Hispaniclati: credit.creditDetails.Hispaniclati === undefined ? false : credit.creditDetails.Hispaniclati,
                        middleEastern: credit.creditDetails.middleEastern === undefined ? false : credit.creditDetails.middleEastern,
                        nativeAmerican: credit.creditDetails.nativeAmerican === undefined ? false : credit.creditDetails.nativeAmerican,
                        pacificIslanderorHawaiian: credit.creditDetails.pacificIslanderorHawaiian === undefined ? false : credit.creditDetails.pacificIslanderorHawaiian,
                        // biRacialMultiRacial: credit.creditDetails.biRacialMultiRacial === undefined ? false : credit.creditDetails.biRacialMultiRacial,
                        other: credit.creditDetails.other === undefined ? false : credit.creditDetails.other,
                        declineToState: credit.creditDetails.declineToState === undefined ? false : credit.creditDetails.declineToState,
                        instagram: credit.creditDetails.instagram ? credit.creditDetails.instagram : '',
                        twitter: credit.creditDetails.twitter ? credit.creditDetails.twitter : '',
                        tiktok: credit.creditDetails.tiktok ? credit.creditDetails.tiktok : '',
                    }
                })
            }
        });
        forceUpdate()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setFormFive({ ...formFive })
        // eslint-disable-next-line
    }, [ProjectInfo])
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    return (
        <div id='downloadStep5'>
            <Header />
            <PreviewFormHeader step={5} />
            <div className="form_inner step_common  step_1">
                <div className="container">
                    <div className='underline'>
                        <div className='step sd_justbetween'>
                            <div className='step_no'>
                                <p>Page 5 of {pafType === 'New Frontier' ? 8 : 9}</p>
                            </div>
                            <div className='step_des'>
                                <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                                <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                            </div>
                        </div>
                    </div>
                    <div className="associate_people pb_0">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">{pafType === 'New Frontier' ? 'Lead Artist(s)' : pafType === 'Indie Series/Special Events' ? 'Top Credit' : 'Director(s)'} Information
                                {/* <Tooltip
                                    id="director"
                                    customClass="tooltip-button tooltip-short-program"
                                    data="Film/Project Title"
                                    place="right"
                                    data_top={25}
                                /> */}
                            </h3>
                        </div>
                        {formFive?.credits.map((item) => {
                            return (
                                <>
                                    {item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formFive.indieTopcredit?.identifier : 'director') ?<>{
                                        item.creditType.map((credit, i) => {
                                            return (
                                                <div className='director sd_container_990'>
                                                    <DirectorInfo disabled={true} DirectorData={credit} item={item} BioValidation={BioValidation} index={i} indieTopcredit={formFive.indieTopcredit}/>
                                                </div>
                                            )
                                        })
                                    }</> : ''}
                                </>
                            )
                        })}

                    </div>
                    {/* <div className="privacy_policy_link">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Privacy Policy</h3>
                        </div>
                        <div className="ass_container_990 ">
                            <p>By signing below you are agreeing to <a href={`https://www.sundance.org/about/privacy-policy`}  rel="noreferrer" target='_blank'>Sundance Institute’s Privacy Policy.</a></p>
                        </div>
                    </div>
                    <div className="electronic_sign">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Electronic Signature</h3>
                        </div>
                        <div className="ass_container_990 ">
                            <p>
                                On behalf of the film/project owners, I certify that the film/project team agrees that all of the above is true to the best of my knowledge and I am authorized to submit this information for this film/project. I understand that this information will appear in all Festival materials and be provided to third-party groups. I understand and consent that the Festival may makeadditional edits to the information submitted here, for reasons including but not limited to style, grammar and brevity.
                            </p>
                            <p> I agree to indemnify and hold harmless the Institute, its officers, directors, employees and other participants in any of the Institute’s programs from any claim of any type made against the Institute arising from the information I have provided here and/or the project’s participation in the Festival.</p>
                        </div>
                    </div> */}
                    <PreviewFormFooter step={5} rootId='downloadStep5'/> 
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Step5