import React, { useState, useEffect } from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import Banner from '../../layout/Banner'
import { Link, useHistory } from "react-router-dom";
import EditFormIcon from '../../icons/EditFormIcon';
import ArrowIcon from '../../icons/ArrowIcon';
import UploaderIcon from '../../icons/UploaderIcon';
import service from '../../common/service';
import { useSelector } from 'react-redux';
import { routeLocater } from '../../common/functions';

function FilmInformation() {
    const apiServe = new service();
    const Loader = apiServe.loader
    const history = useHistory();
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const pafType = useSelector(e => e?.pafType);
    const [, setRouteName] = useState('')
    // const CheckPage = useSelector(e => e.samePage);
    // const [isSamePage, setIsSamePage] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onPAFClick = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            // debugger
            history.push(`/${routeLocater(pafType)}-page${ProjectInfo.submitNo === 9 ? 1 : ProjectInfo.savePageNo ? (pafType === 'New Frontier' && ProjectInfo.savePageNo === 9) ? 8 : ProjectInfo.savePageNo : 1}`)
        }, 1500);
        // setIsSamePage(true)

    }

    const onMaterialsClick = () => {
        window.open(process.env.REACT_APP_MATERIAL_URL + `?uuid=${ProjectInfo.projectUserId}`);

    }

    useEffect(() => {
        // Documentary Features and  Documentary Shorts will be in one form,
        // Indie Series/Special Events will be in one form,
        // New Frontier will be in one form,
        // Narrative Features and Narrative Shorts will be in one form,
        console.log(pafType, 'pafType');
        let data = routeLocater(pafType)
        setRouteName(data)
        // debugger
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div>
                <Header />
                <Banner />
                <div className="sd_container">
                    <div className="sd_auth_content">
                        <div className="sd_information_title">
                            <h2 className="sd_information_title">My Film or Project Information</h2>
                            <p>Congratulations on your film/project's selection into the Sundance Film Festival! </p>
                            <p>Use these tools to provide promotional information about your project to Sundance.</p>
                        </div>
                        <div className="sd_flex sd_flexwrap sd_justbetween">
                            <div className='post_acceptance_link'>
                                <Link onClick={() => onPAFClick()} className="sd_flex sd_aligncenter ">
                                    <EditFormIcon />
                                    <p>Post Acceptance Form</p>
                                    <span className="arrow_icon">
                                        {isLoading ? <img src={Loader} alt='' /> : <ArrowIcon />}
                                    </span>
                                </Link>
                                <p>Use this form to provide information about your film, such as the title, producers, directors, cast, and logline.  Note: Once submitted, this form cannot be updated.</p>
                                <p className='sd_dates'>Due: <br></br>XX/XX/XX, 11:59PM MST</p>
                            </div>
                            { }
                            <div className='post_acceptance_link'>
                                <Link onClick={() => onMaterialsClick()} className="sd_flex sd_aligncenter">
                                    <UploaderIcon />
                                    <p>Materials Uploader</p>
                                    <span className="arrow_icon">
                                        <ArrowIcon />
                                    </span>
                                </Link>
                                <p>Use this form to provide promotional assets such as headshots, stills, trailers, press kits and posters. You may return to this form to provide additional assets at any time.</p>
                                <p className='sd_dates'>Initial Assets Due: <br></br>XX/XX/XX, 11:59PM MST</p>
                                <p className='sd_dates'>Additional Assets Due: <br></br>XX/XX/XX, 11:59PM MST</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default FilmInformation