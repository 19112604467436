import React, { useEffect, useRef, useState } from 'react';
// Layouts
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// PAF Header
import TextField from "../../components/TextField";
import Tooltip from "../../components/Tooltip";
import Dropdown from "../../components/Dropdown";
import FullName from "./../Components/FullName";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COUNTRIES } from '../../common/constant'
import service from '../../common/service';
// import ArrowIcon from '../../icons/ArrowIcon';
import PreviewFormFooter from './PreviewFormFooter';
import Loader from '../../components/Loader';
import PreviewFormHeader from './PreviewFormHeader';
import Demographics from '../Components/Demographics';

let sub
const Step1 = () => {


    const COUNTRIES_LIST = COUNTRIES
    const dispatch = useDispatch();
    // const ProjectGetDetail = useSelector(e => e.SharedGetDetail);
    const [ProjectGetDetail, setProjectGetDetail] = useState({})
    const apiServe = new service();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { ProjectId } = useParams();
    const [pafType, setPafType] = useState({})
    // 
    // const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const [ProjectInfo, setProjectInfo] = useState({})
    const [loglineLength, setLoglineLength] = useState(0)
    const search = useLocation().search
    const referralCode = new URLSearchParams(search).get('referralCode')
    const history = useHistory()

    const [IsLoader, setIsLoader] = useState(true)
    const [formOne, setFormOne] = useState({
        title: ProjectInfo.title,
        article: ProjectInfo.article,
        countryOfOrigin: ProjectInfo.countryOfOrigin,
        filmProjectType: ProjectInfo.filmProjectType,
        credits: ProjectInfo.credits,
        logline: ProjectInfo.logline,
        englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
        premiereStatus: ProjectInfo?.projectInfo?.premiereStatus,
        projectId: ProjectInfo.projectId,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,

    })
    useEffect(() => {
        setFormOne({ ...formOne })
        // eslint-disable-next-line
    }, [ProjectInfo])
    useEffect(() => {
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:referralCode})
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })

        setIsLoader(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|previewForm":
                
                    if (!response.error) {
                        setProjectInfo(response.data)
                        setProjectInfo(response.data)
                        dispatch({ type: 'SHARED_PROJECT_DATA', payload: response.data })
                        dispatch({ type: 'SHARED_PAF_TYPE', payload: response.data.pafType })
                        dispatch({ type: 'REF_CODE', payload: referralCode })
                        setPafType(response.data.pafType)
                        setFormOne({
                            ...formOne,
                            title: response.data.title,
                            article: response.data.article,
                            countryOfOrigin: response.data.countryOfOrigin,
                            filmProjectType: response.data.filmProjectType,
                            credits: response.data.credits,
                            logline: response.data.logline,
                            englishPhoneticProdununciation: response.data.englishPhoneticProdununciation,
                            premiereStatus: response.data.projectInfo.premiereStatus,
                            projectId: response.data._id,
                            indieTopcredit: response.data.indieTopcredit,
                            indieAdditionalTopCredit: response.data.indieAdditionalTopCredit,
                        })
                        apiServe.sendevent('paf|project|getDetail', {})

                        forceUpdate()
                    }
                    break;
                case "paf|project|getDetail":
                    if (!response.error) {

                        dispatch({ type: 'SHARED_GET_DETAILS', payload: response.data })
                        setProjectGetDetail(response.data)
                        setTimeout(() => {
                            setIsLoader(false)
                        }, 500);
                    }
                    break
                case "paf|project|CheckValidEmail":
                    if (!response.error) {
                        setTimeout(() => {
                            setIsLoader(false)
                        }, 500);
                    }else{
                        history.push({pathname:'/',state:{
                            response:response.data.error
                        }})
                    }
                    break
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    var indieFilmCreditLabels = []
    var indieFilmCreditLabelsAdditions = []
    useEffect(() => {
        setFormOne({
            title: ProjectInfo.title,
            article: ProjectInfo.article,
            countryOfOrigin: ProjectInfo.countryOfOrigin,
            filmProjectType: ProjectInfo.filmProjectType,
            credits: ProjectInfo.credits,
            logline: ProjectInfo.logline,
            englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
            premiereStatus: ProjectInfo?.projectInfo?.premiereStatus,
            projectId: ProjectInfo._id,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        // eslint-disable-next-line
    }, [ProjectInfo])

    if (pafType === 'Indie Series/Special Events') {
        // var filteredCreditLabels  = ProjectGetDetail.filmcreditLabels.filter(_=>_.identifier === 'creator' || 'director' || 'showrunner' || 'executiveproducer' || 'co-executiveproducer')
        ProjectGetDetail.filmcreditLabels?.forEach(item => {
            if (item.identifier === 'creator' || item.identifier === 'director' || item.identifier === 'showrunner' || item.identifier === 'executiveproducer' || item.identifier === 'coexecutiveproducer') {
                indieFilmCreditLabels.push(item)

                // formOne.indieTopcredit = { ...indieFilmCreditLabels[0] }
                if (formOne.indieTopcredit?.identifier === '') {
                    // formOne.indieTopcredit = indieFilmCreditLabels[0]
                    formOne.indieTopcredit = { ...indieFilmCreditLabels[0] }
                    // setFormOne({
                    //     ...formOne,
                    //     indieTopcredit: {
                    //         ...formOne.indieTopcredit,
                    //         ...indieFilmCreditLabels[0]
                    //     }
                    // })
                }

            }
            if (item.identifier === 'creator' || item.identifier === 'director' || item.identifier === 'showrunner' || item.identifier === 'executiveproducer' || item.identifier === 'co-executiveproducer' || item.identifier === 'producer' || item.identifier === 'screenwriter') {
                indieFilmCreditLabelsAdditions.push(item)
                if (formOne.indieAdditionalTopCredit.identifier === '' && indieFilmCreditLabelsAdditions.length > 1) {
                    formOne.indieAdditionalTopCredit =  {
                        firstName: '',
                        middleName: '',
                        lastName: '',
                    } 
                    formOne.credits.push({
                        _id: (new Date().getTime() + Math.floor(Math.random() * 1000000000)).toString(),
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        creditType:[{
                            creditDetails: {
                                email: '',
                            }
                        }]
                    })
                }
            }
        })
        console.log(formOne,"indieFilmCreditLabelsAdditions one");
    }

    // useEffect(() => {
    //     let newCredits = formOne.credits
    //     let defaultCredit = {
    //         _id: '',
    //         creditId: '',
    //         creditLabel: '',
    //         identifier: '',
    //         creditType: [{
    //             _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
    //             firstName: '',
    //             middleName: '',
    //             lastName: '',
    //             creditDetails: {
    //                 email: '',
    //             }
    //         }]
    //     }
    //     let DirectorExist = pafType.toLowerCase() === 'new frontier' ? formOne.credits?.findIndex(element => element.identifier === 'leadartist') : formOne.credits.findIndex(element => element.identifier === 'director');
    //     let ScreenWriterExist = pafType.toLowerCase() === 'new frontier' ? formOne.credits?.findIndex(element => element.identifier === 'keycollaborator') : formOne.credits?.findIndex(element => element.identifier === 'screenwriter');
    //     let ProducerExist = formOne.credits?.findIndex(element => element.identifier === 'producer')
    //     let CastExist = formOne.credits.findIndex(element => element.identifier === 'principalcast')

    //     if (pafType === 'Indie Series/Special Events') {
    //         DirectorExist = formOne.credits.findIndex(element => element.identifier === formOne.indieTopcredit.identifier)
    //         ScreenWriterExist = formOne.credits.findIndex(element => element.identifier === formOne.indieAdditionalTopCredit.identifier)
    //     }

    //     let filmcreditLabels = pafType === 'New Frontier' ? JSON.parse(JSON.stringify(ProjectGetDetail.newFrontierCreditLabel)) : JSON.parse(JSON.stringify(ProjectGetDetail.filmcreditLabels))
    //     if (DirectorExist === -1) {
    //         let newD = JSON.parse(JSON.stringify(defaultCredit))
    //         newD._id = (new Date().getTime() + Math.floor(Math.random() * 1000000000)).toString()
    //         newD.creditId = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === "leadartist")._id : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit._id : filmcreditLabels.find(_ => _.identifier === 'director')._id
    //         newD.creditLabel = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'leadartist').name : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit.name : filmcreditLabels.find(_ => _.identifier === 'director').name
    //         newD.identifier = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'leadartist').identifier : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit.identifier : filmcreditLabels.find(_ => _.identifier === 'director').identifier
    //         formOne.credits.push(newD)
    //         setFormOne({ ...formOne, credits: newCredits })
    //         // console.log('=-=-=> Effect', formOne);
    //     }
    //     if (ScreenWriterExist === -1) {
    //         let newS = JSON.parse(JSON.stringify(defaultCredit))
    //         newS._id = (new Date().getTime() + Math.floor(Math.random() * 100)).toString()
    //         newS.creditId = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'keycollaborator')._id : pafType === 'Indie Series/Special Events' ? formOne.indieAdditionalTopCredit._id : filmcreditLabels.find(_ => _.identifier === 'screenwriter')._id
    //         newS.creditLabel = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'keycollaborator').name : pafType === 'Indie Series/Special Events' ? formOne.indieAdditionalTopCredit.name : filmcreditLabels.find(_ => _.identifier === 'screenwriter').name
    //         newS.identifier = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'keycollaborator').identifier : pafType === 'Indie Series/Special Events' ? formOne.indieAdditionalTopCredit.identifier : filmcreditLabels.find(_ => _.identifier === 'screenwriter').identifier
    //         formOne.credits.push(newS)
    //         setFormOne({ ...formOne, credits: newCredits })
    //         // setFormOne({ ...formOne, credits: newCredits })
    //     }
    //     if (pafType !== 'New Frontier' && pafType !== 'Indie Series/Special Events') {
    //         if (ProducerExist === -1) {
    //             let newP = JSON.parse(JSON.stringify(defaultCredit))
    //             newP._id = (new Date().getTime() + Math.floor(Math.random() * 100000)).toString()
    //             newP.creditId = filmcreditLabels.find(_ => _.identifier === 'producer')._id
    //             newP.creditLabel = filmcreditLabels.find(_ => _.identifier === 'producer').name
    //             newP.identifier = filmcreditLabels.find(_ => _.identifier === 'producer').identifier
    //             formOne.credits.push(newP)
    //         }
    //         // setFormOne({ ...formOne, credits: newCredits })
    //     }
    //     if (pafType !== 'Documentary Features' && pafType !== 'Documentary Shorts') {
    //         if (CastExist === -1) {
    //             let newC = JSON.parse(JSON.stringify(defaultCredit))
    //             newC._id = (new Date().getTime() + Math.floor(Math.random() * 1000)).toString()
    //             newC.creditId = filmcreditLabels.find(_ => _.identifier === 'principalcast')._id
    //             newC.creditLabel = filmcreditLabels.find(_ => _.identifier === 'principalcast').name
    //             newC.identifier = filmcreditLabels.find(_ => _.identifier === 'principalcast').identifier
    //             formOne.credits.push(newC)
    //             // setFormOne({ ...formOne, credits: newCredits })
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [formOne.credits, formOne.indieTopcredit, formOne.indieAdditionalTopCredit])



    const onChangeCredit = (e, CID, IID) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let newCredit = formOne.credits
        newCredit.forEach(credit => {
            if (credit._id === CID) {
                credit.creditType.forEach(data => {
                    if (data._id === IID) {
                        const { name, value } = e.target
                        data[name] = value
                    }
                })
            }
        });
        forceUpdate()
    }

    useEffect(() => {

        let len = formOne?.logline?.split(' ')
        len = len?.filter(_ => _ !== '');
        setLoglineLength(len?.length)

    }, [formOne.logline])
    const getCredit = (CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let ADD_LIMIT
        let TITLE
        if (pafType === 'New Frontier') {
            ADD_LIMIT = CREDIT_TYPE === 'leadartist' ? 4 : CREDIT_TYPE === 'keycollaborator' ? 3 : 6
            TITLE = CREDIT_TYPE === 'leadartist' ? 'Lead Artist' : CREDIT_TYPE === 'keycollaborator' ? 'Key Collaborator' : 'principalcast'
        }
        else if (pafType === 'Indie Series/Special Events') {
            if (TYPE === 'Top') {
                ADD_LIMIT = 4
                TITLE = formOne.indieTopcredit.name
            } else if (TYPE === 'Additional') {
                ADD_LIMIT = 3
                TITLE = formOne.indieAdditionalTopCredit.name
            } else {
                ADD_LIMIT = 6
                TITLE = 'principalcast'
            }
        }
        else {
            ADD_LIMIT = CREDIT_TYPE === 'director' ? 4 : CREDIT_TYPE === 'screenwriter' ? 3 : 6
            TITLE = CREDIT_TYPE === 'director' ? 'Director' : CREDIT_TYPE === 'screenwriter' ? 'Screenwriter' : CREDIT_TYPE === 'producer' ? 'Producer' : 'principalcast'
        }
        return formOne.credits?.map((item, i) => {
            let CreditLabelID
            if (pafType === 'New Frontier') {
                CreditLabelID = ProjectGetDetail?.newFrontierCreditLabel?.find(_ => _.identifier === CREDIT_TYPE)?._id
            }
            else {
                CreditLabelID = ProjectGetDetail?.filmcreditLabels?.find(_ => _.identifier === CREDIT_TYPE)?._id
            }
            return (
                <>
                    {item.creditId === CreditLabelID ?
                        item.creditType.map((credit, i) => {
                            <FullName disabled={true} credit={credit} item={item} onChange={onChangeCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType === 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType === 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : i + 1}`} />

                            return (
                                <>
                                    <div key={credit._id} className="sd_flex sd_aligncenter associate_list">
                                        <FullName disabled={true} credit={credit} item={item} onChange={onChangeCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType === 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType === 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : i + 1}`} />
                                    </div>
                                </>
                            )
                        })
                        : ''}
                </>)
        })
    }
    return (
        <>
            {IsLoader ? <Loader /> : <>
                <div id='downloadStep1'>
                    <Header />
                    <PreviewFormHeader step={1} />
                    {/* {console.log('==-=-=-=> render', formOne)} */}
                    <div className="form_inner step_1 step_common">
                        <div className="container">
                            <div className="f_step_1_heading sd_flex sd_justbetween">
                                <div className="f_step_1_left_title">
                                    {/* <h1>Program Announcement Information</h1>
                                    <p> This information will be used in our public program announcement. Additional credits may be added in the next step.</p> */}
                                     <h2>Please have all of your film/project information with you before you begin.</h2>
                                </div>
                                <div className="f_step_1_right_desc">
                                    <h5>Program Announcement Information</h5>
                                    <p>This information will be used in our public program announcement. Additional credits may be added in the next step.</p>
                                    <p>After you submit each step, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>
                                    <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                                </div>
                            </div>
                            <div className="film_deatil_form">
                                <div className="form_inner_title">
                                    <h3 className="sd_flex sd_aligncenter">Film/Project Title </h3>
                                </div>
                                <div className="film_deatil_form_inner">
                                    <div className="sd_flex f_detail_f_row sd_justbetween">
                                        <TextField disabled={true} uppercase={true} label="article" name="article" value={formOne.article} required="false" />
                                        <TextField disabled={true} uppercase={true} label="Title" name="title" value={formOne.title} required="false" desc={''}/>
                                    </div>
                                    <p className='mb_25'>Please submit your project title in Title Case (first letter of each word capitalized, minor words are lowercase). We do not write film titles in all-capitals unless it is an intentional stylistic choice.</p>
                                    <div className="sd_flex f_detail_s_row sd_justbetween">
                                        <Dropdown disable={true} uppercase={true} name="filmProjectType" label="Film/Project Type" options={ProjectGetDetail.pafProjectType} value={formOne.filmProjectType} />
                                        <TextField disabled={true} uppercase={true} name='englishPhoneticProdununciation' value={formOne.englishPhoneticProdununciation} label="English Phonetic Pronunciation" required="false" />
                                    </div>
                                    {formOne.countryOfOrigin?.map((item, i) => {
                                        return (
                                            <>
                                            <div className='detail_country'>
                                                <div className="sd_flex sd_aligncenter f_detail_country_row">
                                                    <Dropdown key={item._id} disable={true} name='Country of Origin' value={item.country ? item.country : ''} uppercase={true} optionID={item.id} label="Country of Origin" DefaultOption={'Select Country'} options={COUNTRIES_LIST} />
                                                </div>
                                            </div>
                                            <p className='mb_25'>Country of origin is based on where the majority of the project’s financing originated. If your project was financed in the United States but produced in another country, you may list both countries in the order you choose. Up to 4 Countries may be listed.</p>
                                            </>)
                                    })}
                                    <div className="sd_flex f_detail_s_row sd_justbetween">
                                        <Dropdown name="premiereStatus" disable={true} value={formOne.premiereStatus} label="Premiere Status" uppercase={true}
                                            toolId={'premiereStatus'} options={['World Premiere', 'International Premiere', 'North American Premiere', 'U.S. Premiere', 'None']} DefaultOption={'Select From Below'} />
                                    </div>
                                    <p className='pl_25'>
                                        <ul>
                                            <li><strong>World Premiere:</strong> Completed film/project has never exhibited publicly before</li>
                                            <li><strong>International Premiere:</strong>  Completed film/project has never screened publicly outside of its country of origin  </li>
                                            <li><strong>North American Premiere:</strong> Completed film/project has never screened publicly in North America (Canada, U.S.A., or Mexico)</li>
                                            <li><strong> U.S. Premiere: </strong> Completed film/project has never screened publicly in the U.S.A.</li>
                                            <li><strong> None: </strong>  Completed film/project has screened publicly in the U.S.A. </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="associate_people">
                                <div className="form_inner_title">
                                    <h3 className="sd_flex sd_aligncenter">Associated People</h3>
                                </div>
                                <div className="ass_container_990">
                                    <div className={`form_inner_sub_title sd_flex sd_aligncenter ${pafType === 'Indie Series/Special Events' ? 'sd_justbetween' : ''}`}>
                                        <div className="sd_flex sd_aligncenter ind_title_row">
                                            <h5 className="mr_10">{pafType === 'New Frontier' ? 'Lead Artist(s)' : pafType === 'Indie Series/Special Events' ? 'Top Credit(s)' : 'Director(s)'}</h5>
                                            {(pafType === 'Indie Series/Special Events') && <div className="no_margin mr_10"> <Dropdown disable={true} creditOption={true} options={indieFilmCreditLabels} optionID='TOP' value={formOne?.indieTopcredit?._id} />  </div>}
                                        </div>
                                        <p className="ml_0">Up to 4 individuals; list in credit order for Festival publications. For these individuals we will include their headshot and bio in our online Festival Program Guide and we ask that they provide a "Meet the Artist" video.</p>
                                    </div>
                                </div>
                                {
                                    pafType === 'New Frontier' ? getCredit('leadartist') : pafType === 'Indie Series/Special Events' ? getCredit(formOne.indieTopcredit.identifier, 'Top') : getCredit('director')
                                }
                                <div className="ass_container_990">
                                    <div className={`form_inner_sub_title sd_flex sd_aligncenter mt-40 ${pafType === 'Indie Series/Special Events' ? 'sd_justbetween' : ''} `}>
                                        <div className="sd_flex sd_aligncenter ind_title_row">
                                            <h5 className="mr_10">{pafType === 'New Frontier' ? 'Key Collaborator(s)' : pafType === 'Indie Series/Special Events' ? 'Additional Top Credit(s)' : 'Screenwriter(s)'} </h5>
                                            {(pafType === 'Indie Series/Special Events') && <div className="no_margin mr_10">  <Dropdown disable={true} creditOption={true} options={indieFilmCreditLabelsAdditions} DefaultOption={formOne?.indieAdditionalTopCredit?._id ? formOne?.indieAdditionalTopCredit?.name : 'Select From Below'} optionID='ADD' value={formOne?.indieAdditionalTopCredit?._id} /> </div>}
                                        </div>
                                        <p className="ml_0">List in desired order for all places where credits appear. Up to 3.</p>
                                    </div>
                                </div>
                                {
                                    // getCredit('Screenwriter')
                                    pafType === 'New Frontier' ? getCredit('keycollaborator') : pafType === 'Indie Series/Special Events' ? getCredit(formOne.indieAdditionalTopCredit.identifier, 'Additional') : getCredit('screenwriter')
                                }
                                {pafType === 'New Frontier' || pafType === 'Indie Series/Special Events' ? "" : <> <div className="ass_container_990">
                                    <div className="form_inner_sub_title sd_flex sd_aligncenter mt-40">
                                        <h5>Producer(s)
                                            {/* <Tooltip id="accessibility" customClass="tooltip-button tooltip-short-program" data="Include up to six names of individuals receiving full producer credit only (no executive producers, co-producers, associate producers, etc.).

                                        List in desired order for press announcement." place="right" data_top={25} /> */}
                                        </h5>
                                        <p>List in desired order for all places where credits appear.</p>
                                    </div>
                                </div>
                                    {
                                        getCredit('producer')
                                    }
                                </>}

                                {pafType === 'Documentary Features' || pafType === 'Documentary Shorts' ? "" : <><div className="ass_container_990">
                                    <div className="form_inner_sub_title sd_flex sd_aligncenter mt-40">
                                        <h5>Principal Cast Member(s)</h5>
                                        <p>List in desired order for all places where credits appear. Up to 6.</p>
                                    </div>
                                </div>
                                    {
                                        getCredit('principalcast')
                                    }</>
                                }

                            </div>
                            <div className="associate_people">
                                <div className="form_inner_title">
                                    <h3 className="sd_flex sd_aligncenter">{pafType === 'Indie Series/Special Events' ? `Top Credit's` : 'Director'} Demographics</h3>
                                </div>
                                <div className='demographic_desc'>
                                    <p>Collecting demographic data is a critical step in helping us support and promote your film/project and fully reflect the program composition. We use this information to create editorial posts on our site and official social channels, and for press purposes, including specialized media outreach, external-facing fact sheets detailing overall demo data for the festival (i.e. “30 Projects by women filmmakers of color” or “60% of films in the U.S. Documentary Competition were directed by people of color”) and coverage. Examples of the type of coverage include “Films from Black Filmmakers We Can’t Wait to See,” “LGBTQ+ Sundance Selections To Watch For,” “Indigenous Films Featured at Sundance Film Festival,” and similar.</p>

                                    <p>Please note if you decline to state in any category, then we are unable to include you and/or your project in these Sundance created lists on our house channels or presented to media for this specific coverage. Feel free to reach out to your Publicity Liaison should you have any questions about the information or its use. "</p>
                                </div>
                                <div className="ass_container_990">
                                    {
                                        formOne && formOne.credits && formOne.credits.map(item => {

                                            if (item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit.identifier : 'director')) {
                                                return item?.creditType.map((credit, i) => {
                                                    return <Demographics title={item.identifier} disabled={true} item={item} index={i} DirectorData={credit} isShare={true} />
                                                })
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="logline_form">
                                <div className="form_inner_title">
                                    <h3 className="sd_flex sd_aligncenter required">Logline</h3>
                                    <p>Below is a logline for your film/project based on your initial submission via FilmFreeway.<strong>THIS IS THE FINAL LOGLINE.</strong> We will consider edits if there are factual errors or inaccuracies.</p>
                                    <p>It is drafted in Associated Press style with Sundance house guidelines by our PR, editorial, and programming teams. This will be used in our press release and marketing materials such as the website.</p>
                                    <p>Loglines for this use describe the plot of the film/project and follow Sundance house guidelines, which include: {(pafType === 'Narrative Shorts' || pafType === 'Documentary Shorts') ? '40' : '50'} words max.</p>
                                    <p>No film titles, filmmaker or actor names in the logline (directors, screenwriters, producers, actors are already directly attached to the logline as a part of the credit list).</p>
                                    <p>No descriptions of the type of film, genre, nor self-describe or editorializing (i.e. “this groundbreaking documentary”, “a coming-of-age drama”, “a story about”, “a cinematic journey”).</p>
                                    <p>No spoilers or major plot points that are intended to be discovered by the audience.</p>
                                </div>
                                <div className="ass_container_990">
                                    <div className="field_row_inner">
                                        <label>Max {pafType === 'Narrative Shorts' || pafType === 'Documentary Shorts' ? 40 : 50} Words  </label>
                                        <div className="field_input relative">
                                            <textarea disabled={true} value={formOne.logline} name='logline' />
                                        </div>
                                        <div className="textarea_word sd_flex sd_aligncenter">
                                            <label>Word Count</label>
                                            <span>{loglineLength}/{pafType === 'Narrative Shorts' || pafType === 'Documentary Shorts' ? 40 : 50}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PreviewFormFooter step={1} rootId='downloadStep1'/>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>}</>
    )
}
export default Step1
