import React, { Suspense, useEffect, useState } from "react";
import Routers from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import history from "./history";
import Idle from 'react-idle'
import Cookies from "universal-cookie";
import service from "./common/service";
import { useDispatch } from "react-redux";
import SuccessMessage from "./PAF/Components/SuccessMessage";
import Loader from "./components/Loader";

const cookies = new Cookies();
let User = cookies.get("PAFUser");
let sub;

const App = () => {
    const [apiServe, setApiServe] = useState()
    const dispatch = useDispatch();
    const [SubmitSuccess, setSubmitSuccess] = useState()

    useEffect(() => {
        // if (window.location.hostname === 'paf.sundance.org') {
        //     window.location.href = process.env.REACT_APP_HOME_URL
        //   }
        window.open = function (open) {
            return function (url, name, features) {
                if(url.includes(process.env.REACT_APP_HOME_URL) && window.location.origin.includes("prod")){
                    url = url.replace(process.env.REACT_APP_HOME_URL,window.location.origin.replace("paf","festival")+'/')
                } else {
                    url = url.replace(process.env.REACT_APP_PAF_HOME,window.location.origin+'/')
                }
                // set name if missing here
                name = name || "default_window_name";
                return open.call(window, url, name, features);
            };
        }(window.open);
        setApiServe(new service())
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (apiServe) {
                apiServe.connect((flag) => {
                    if (flag) {
                        console.log('CONNECTED...');
                        let particularUser = cookies.get("PAFUser")
                        if (particularUser) {
                            apiServe.sendevent("authentication", {
                                token: particularUser.token
                            });
                        }
                    }
                });
            }
            else {
                setApiServe(new service())
            }
        }, 500);
    }, [apiServe])

    useEffect(() => {
        if (apiServe) {
            apiServe.sendevent("admin|getDateConfiguration", {host:process.env.REACT_APP_INSTANCE});
            sub = apiServe.getevent().subscribe((response) => {
                switch (response.event) {
                    case "authentication":
                        if (!response.error) {
                            if(response.data.user.isDeleted){
                                this.setState({isLoading: false }, () => {});
                                if(!localStorage.getItem('isForceLogout')){
                                    logout()
                                }
                            } else {
                                if(cookies.get('forcelogoutkey')){
                                    let oldKey = cookies.get('forcelogoutkey')
                                    let newKey = response.data.user.forceLogoutKey
                                    if(oldKey !== newKey){
                                        logout()
                                    }
                                } else {
                                    if(cookies.get('forcelogoutkey') === '' && response.data.user.forceLogoutKey !== ''){
                                        logout()
                                    } else {
                                        cookies.set("forcelogoutkey", response.data.user.forceLogoutKey, {
                                            domain: process.env.REACT_APP_COOKIE_DOMAIN,
                                            path: "/",
                                        })
                                        console.log('AUTHENTICATED...');
                                        cookies.set("PAFUser", response.data, {
                                            domain: process.env.REACT_APP_COOKIE_DOMAIN,
                                            path: "/"
                                        });
                                        let particularUser = cookies.get("PAFUser")
                                        if (particularUser) {
                                            apiServe.sendevent('user|getProfile', {})
                                        }
                                    }
                                }
                            }
                        } else {
                            if (response.code === "404" || response.code === "401") {
                                logout()
                                history.push("/");
                            }
                        }
                        break;
                    case "user|getProfile":
                        if (!response.error) {
                            console.log('GET PROFILE...');
                            dispatch({ type: 'USER', payload: response.data })
                            let PAFProjectId = cookies.get('PAFProjectId')
                            apiServe.sendevent('paf|project|getProjectInfo', { projectUserId: PAFProjectId })
                        } else if (response.error) {
                            if (response.code === "401") {
                                logout()
                                history.push("/");
                            }
                        }
                        break;
                    case "paf|project|getProjectInfo":
                        if (!response.error) {
                            dispatch({ type: 'FORM_DATA', payload: response.data })
                            dispatch({ type: 'PAF_TYPE', payload: response.data.pafType })
                            apiServe.sendevent('paf|project|getDetail', {})

                        }
                        break;
                    case "paf|project|getDetail":
                        if (!response.error) {
                            dispatch({ type: 'PROJECT_GET_DETAILS', payload: response.data })
                        }
                        break;
                    case "paf|project|allUpdate":
                        if (response.error) {
                            if (response.code === "401") {
                                window.location.reload()
                            }
                        }
                        break;
                    case "paf|project|submit":
                        if (!response.error) {
                            setSubmitSuccess(true)
                            setTimeout(() => {
                                setSubmitSuccess(false)
                            }, 5000);
                        }
                        break;
                    case "paf|project|previewForm":
                        if (!response.error) {
                            dispatch({ type: 'SHARED_PROJECT_DATA', payload: response.data })
                        }
                        break;
                    case "paf|project|CheckValidEmail":
                        if (!response.error) {
                            
                        }else{
                            // history.push('/')
                            window.open(process.env.REACT_APP_PAF_HOME,'_self')
                        }
                        break
                    case "forceLogout":
                        if(!response.error){
                            localStorage.setItem('isForceLogout',true)
                            if(response.data.is_deleted){
                                window.open(process.env.REACT_APP_HOME_URL + "logout", "_self");
                            } else{
                                logout()
                            }
                        }
                        break;
                    case "admin|getDateConfiguration":
                        if (!response.error) {
                            const convertedObject = response.data.reduce((acc, item) => {
                            acc[item.module] = item;
                            return acc;
                            }, {});
                            localStorage.setItem('MENUE_ENABLE_DATE', JSON.stringify(convertedObject))
                            dispatch({type: 'MENUE_ENABLE_DATE', res: convertedObject})
                        }
                        break;
                    default:
                        break;
                }
            })
        }
        return () => {
            if (sub) sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [apiServe])

    const logout = () => {
        let PAFUser = cookies.get("PAFUser");
        if (PAFUser && PAFUser.token) {
            dispatch({ type: 'USER', payload: {} })
            // eslint-disable-next-line
            document.cookie = "PAFUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie = "PAFProjectId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie = "forcelogoutkey" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

            cookies.remove("PAFUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
            cookies.remove("PAFProjectId", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
            cookies.remove("forcelogoutkey", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
            document.cookie = '';
            let Zone = localStorage.getItem('TIMEZONE')
            localStorage.clear();
            if (Zone) localStorage.setItem('TIMEZONE', Zone)
            window.open(process.env.REACT_APP_PAF_HOME,'_self')
            // window.open(process.env.REACT_APP_HOME_URL + 'sign-in', "_self")
        }
    };
    const closeSubmit = () => {
        setSubmitSuccess(false)
    }

    return (
        <>
            {/* <SuccessMessage step={9} active={SubmitSuccess} close={closeSubmit} text={'Submission'} /> */}
            <Router>
                <Suspense fallback={<Loader/>}>
                    <Idle timeout={process.env.REACT_APP_IDLE_LOGOUT_TIME} render={({ idle }) => <h1>{idle ? logout() : ""}</h1>} />
                    <Routers history={history} />
                </Suspense>
            </Router>
        </>
    );
}

export default App;

