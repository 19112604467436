import React, { useState, useEffect  } from 'react';
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import PreviewFormFooter from './PreviewFormFooter';
import TextField from "../../components/TextField";
import Dropdown from "../../components/Dropdown";
import TextArea from '../../components/TextArea';
import {  useSelector } from 'react-redux';
import PreviewFormHeader from './PreviewFormHeader';
import Tooltip from '../../components/Tooltip';
import { COUNTRIES } from '../../common/constant';
import { useParams } from 'react-router-dom';
import service from '../../common/service';
import Checkbox from '../../components/Checkbox';

 const Step3=()=> {
    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const ProjectGetDetail = useSelector(e => e.SharedGetDetail);
    const pafType = useSelector(e => e.SharedPafType);
    const COUNTRIES_LIST = COUNTRIES

    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
     const [formThree, setformThree] = useState({
        title: ProjectInfo.title,
        article: ProjectInfo.article,
        countryOfOrigin: ProjectInfo.countryOfOrigin,
        filmProjectType: ProjectInfo.filmProjectType,
        englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
        aspectRatio: ProjectInfo.projectInfo.aspectRatio,
        captionAudioInDCP: ProjectInfo.projectInfo.captionAudioInDCP,
        captionAudioInDCPOther: ProjectInfo.projectInfo.captionAudioInDCPOther,
        aiAspect: ProjectInfo.projectInfo.aiAspect,
        aiAspectOther: ProjectInfo.projectInfo.aiAspectOther,
        color: ProjectInfo.projectInfo.color,
        finalRunTime: ProjectInfo.projectInfo.finalRunTime,
        inPersonFormat: ProjectInfo.projectInfo.inPersonFormat,
        includeStroboscopicImg: ProjectInfo.projectInfo.includeStroboscopicImg,
        allOfYourScreening: ProjectInfo.projectInfo.allOfYourScreening,
        repeatScreening: ProjectInfo.projectInfo.repeatScreening,
        premiereScreening: ProjectInfo.projectInfo.premiereScreening,
        requiredDCP: ProjectInfo.projectInfo.requiredDCP,
        requiredDCPAndProResFile: ProjectInfo.projectInfo.requiredDCPAndProResFile,
        requiredDCPAndProResFileOther: ProjectInfo.projectInfo.requiredDCPAndProResFileOther,
        requiredDCPOther: ProjectInfo.projectInfo.requiredDCPOther,
        premiereScreeningOther: ProjectInfo.projectInfo.premiereScreeningOther,
        repeatScreeningOther: ProjectInfo.projectInfo.repeatScreeningOther,
        allOfYourScreeningOther: ProjectInfo.projectInfo.allOfYourScreeningOther,
        language: ProjectInfo.projectInfo.language,
        onlineFormat: ProjectInfo.projectInfo.onlineFormat,
        premiereStatus: ProjectInfo.projectInfo.premiereStatus,
        soundFormat: ProjectInfo.projectInfo.soundFormat,
        timecodeOccursInProject: ProjectInfo.projectInfo.timecodeOccursInProject,
        yearFinished:ProjectInfo.projectInfo.yearFinished,
        runTime:ProjectInfo.projectInfo.runTime,
        subtitles:ProjectInfo.projectInfo.subtitles

     })
     const [digitalMedia, ] = useState({
        facebook: ProjectInfo.digitalMedia.facebook,
        hastag: ProjectInfo.digitalMedia.hastag,
        instagram: ProjectInfo.digitalMedia.instagram,
        officialWebsite: ProjectInfo.digitalMedia.officialWebsite,
        twitter: ProjectInfo.digitalMedia.twitter,
        youtubeOrVimeoTrailer: ProjectInfo.digitalMedia.youtubeOrVimeoTrailer,
        tiktok: ProjectInfo.digitalMedia.tiktok,
     })
    const [state] = useState({
        PremiereStatus:['World Premiere','International Premiere','North American Premiere','U.S. Premiere','None'],
        InpersonFormat:['DCP 24fps','DCP 25fps','DCP 30fps','Other'],
        OnlineFormat:['H264 24fps','H264 25fps /stereo','H264 30fps','Apple Pro Res LT 24fps','Apple Pro Res LT 25fps','Apple Pro Res LT 30fps'],
        AspectRatio:['Flat - 2.0',' Flat - 1.85','Flat - 1.78','Flat - 1.66','Flat - 1.37','Scope - 2.35','Other',"Don't Know"],
        SoundFormat:['7.1', '5.1', '3', '2','Mono','Atmos',' Other',"Don't Know"],
        Color:['Color', 'Black & White', 'Color & Black & White'],
        Subtitles:['Yes','No','Some - Mixed Language'],
        PremiereOpenCaption: ['Yes', 'No'],
        RepeatOpenCaption: ['Yes', 'No'],
        allOfYourScreeningOther: ['Yes, I would like all of our screenings to be Open Caption', 'No, I would like only one of our screenings to be Open Caption'],
        FinalRunTime:['Yes','No'],
        captions:["Yes, Closed Captioning", "Yes, Audio Descriptive Services","Yes, Both","Not Available"],
        stroboscopic:['Yes','No'],
        aiAspect:['Yes','No'],

    })

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])

    useEffect(() => {
        setformThree({ ...formThree })
        // eslint-disable-next-line
    }, [ProjectInfo])
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])  
    
 
  const  getYears = () => {
        var years = ["select from Below"];
        var startYear = 1900;
         // const year = new Date().getFullYear();
         const year =  new Date().getFullYear()+2;;

        for (var start = startYear; start <= year; start++) {
          years.push(start.toString());
        }

        return years;
    };
    const year = getYears(); 
    
    return (
        <div id='downloadStep3'>
            <Header />
            <PreviewFormHeader step={3}/>
            <div className="container form_inner step_common">
                <div className='underline'>
                    <div className='step sd_justbetween'>
                        <div className='step_no'>
                            <p>Page 3 of {pafType === 'New Frontier' ? 8 : 9}</p>
                        </div>
                        <div className='step_des'>
                        <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

<p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                        </div>
                    </div>
                </div>
                <div className="film_deatil_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Film/Project Title</h3>
                    </div>
                    <div className="film_deatil_form_inner">
                        <div className="sd_flex f_detail_f_row sd_justbetween">
                            <TextField disabled={true} uppercase={true} label="article" name="article" value={formThree.article} required="false" />
                            <TextField disabled={true} uppercase={true} label="Title" name="title" value={formThree.title} required="false" />
                        </div>
                        <p className='mb_25'>Submit in title case (i.e., Best Film Ever, not all caps, i.e., BEST FILM EVER). If your title begins with an article ("A," "The," or an equivalent in another language), please enter that word in the Article field.</p>
                        <div className="sd_flex f_detail_s_row sd_justbetween">
                            <Dropdown disable={true} uppercase={true} name="filmProjectType" label="Film/Project Type" options={ProjectGetDetail.pafProjectType} value={formThree.filmProjectType} />
                            <TextField disabled={true} uppercase={true} name='englishPhoneticProdununciation' value={formThree.englishPhoneticProdununciation} label="English Phonetic Pronunciation" required="false" />
                        </div>

                        {formThree.countryOfOrigin?.map((item, i) => {
                            return (
                                <>
                                    <div className="sd_flex sd_aligncenter f_detail_country_row">
                                        <Dropdown key={item._id} disable={true} name='Country of Origin' value={item.country ? item.country : ''} uppercase={true} optionID={item.id} label="Country of Origin" DefaultOption={'Select Country'} options={COUNTRIES_LIST} />
                                    </div>
                                    <p className='mb_25 mt_10'>Country of origin is based on where the majority of the project’s financing originated. If your project was financed in the United States but produced in another country, you may list both countries in the order you choose. Up to 4 Countries may be listed.</p>
                                </>)
                        })}
                    </div>
                </div>
                <div className="project_info_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Project Info</h3>
                    </div>
                    <div className="project_info_form_inner sd_container_990 ">
                        <div className="project_info_form_inner_top">
                            <div className="sd_flex top_2_field">
                                <Dropdown name="premiereStatus" disable={true} value={formThree.premiereStatus} label="Premiere Status" uppercase={true}  options={state.PremiereStatus} DefaultOption={'Select From Below'} toolId={'Premiere'} />
                                <Dropdown disable={true}  name="yearFinished" value={formThree.yearFinished}label="Year Finished"  uppercase={true}  options={year} toolId = {'Finished'}  DefaultOption={'Select From Below'}  />
                                
                 
                            </div>
                            <p className='pl_25 mb_25'>
                                <ul>
                                    <li><strong>World Premiere:</strong> Completed film/project has never exhibited publicly before</li>
                                    <li><strong>International Premiere:</strong>  Completed film/project has never screened publicly outside of its country of origin  </li>
                                    <li><strong>North American Premiere:</strong> Completed film/project has never screened publicly in North America (Canada, U.S.A., or Mexico)</li>
                                    <li><strong> U.S. Premiere: </strong> Completed film/project has never screened publicly in the U.S.A.</li>
                                    <li><strong> None: </strong>  Completed film/project has screened publicly in the U.S.A. </li>
                                </ul>
                            </p>
                            {/*<div className="sd_flex sd_justbetween">
                                <Dropdown disable={true}  name="inPersonFormat" value={formThree.inPersonFormat} label="In-Person Format" uppercase={true} DefaultOption={'Select From Below'} options={state.InpersonFormat} />
                              
                                <Dropdown disable={true}  name="onlineFormat" value={formThree.onlineFormat} label="Online Format" uppercase={true} DefaultOption={'Select From Below'} options={state.OnlineFormat}/>
                                <Dropdown disable={true}  name="aspectRatio" value={formThree.aspectRatio} label="Aspect Ratio" uppercase={true} DefaultOption={'Select From Below'} options={state.AspectRatio}/>
                            </div> */}
                            <div className="sd_flex sd_aligncenter top_2_field">
                                {/* <Dropdown disable={true}  name="soundFormat" value={formThree.soundFormat}  label="Sound Format" uppercase={true} DefaultOption={'Select From Below'} options={state.SoundFormat}/> */}
                                <Dropdown disable={true}  name="color" value={formThree.color} label="color" uppercase={true} DefaultOption={'Select From Below'} options={state.Color} />
                                <TextField disabled={true} type="text"  name="runTime" value={formThree.runTime} uppercase={true} label="Run Time (mins)" required="false" />
                                
                            </div>
                            <div className="sd_flex sd_aligncenter sd_justbetween">
                              <TextField disabled={true} type="text"  name="language" value={formThree.language}  uppercase={true} label="language"required="false" />
                              <Dropdown disable={true}   name="subtitles" label="Subtitles (for non English spoken)" value={formThree.subtitles}  uppercase={true} DefaultOption={'Select From Below'} options={state.Subtitles} />
                                <Dropdown disable={true}  name="finalRunTime" value={formThree.finalRunTime}  label="Final Run Time ? "  uppercase={true} DefaultOption={'Select From Below'} options={state.FinalRunTime} />
           
                            </div>
                        </div>
                        <div className="project_info_form_inner_bottom border_bottom_gray">
                            <div className='sd_page3_heading'>
                                <p><i>For Festival participation, English Language Projects are required to deliver their DCP for in-person screenings with Closed Captions (CCAP) AND Open Captions (OCAP), and their ProRes file for online screenings with Closed Captions (CC). Non English language Projects must provide BOTH standard English subtitles and SDH Subtitles, (Subtitles for the deaf and hard of hearing).</i></p>
                                <p><i>The Festival will schedule at least one in-person Open Caption screening of every Project in the Festival program. The timing of these screenings will be confirmed by the end of December, 2024.</i></p>
                            </div>
                            <div className="sd_flex">
                                <div className="share_check">
                                <Checkbox type="checkbox" name={'requiredDCPAndProResFile'} label={'I understand that Closed Captions are required for our DCP (in-person) & ProRes file (online). Closed Captions show spoken English and audio descriptions for Deaf and hard of hearing viewers. I understand and agree that if the Closed Caption file is faulty in any way, Sundance Institute may elect to use the Open Caption file for all in- person public screenings. *'} value={formThree.requiredDCPAndProResFile} checked={formThree.requiredDCPAndProResFile} id={'requiredDCPAndProResFile'} />
                                </div>
                            </div>
                            {formThree.requiredDCPAndProResFile && <TextArea disabled={true} name="requiredDCPAndProResFileOther" value={formThree.requiredDCPAndProResFileOther} />}
                            <div className="sd_flex">
                                <div className="share_check">
                                <Checkbox type="checkbox" name={'requiredDCP'} label={'I understand that an Open Caption version of our DCP is required. Open Captions show spoken English and audio descriptions on screen for Deaf and hard of hearing viewers at in-person screenings. Non-English language projects are required to provide an SDH ("Subtitles for the deaf and hard of hearing") version to be used for Open Caption screenings. *'} value={formThree.requiredDCP} checked={formThree.requiredDCP} id={'requiredDCP'} />
                                </div>
                            </div>
                            {formThree.requiredDCP && <TextArea disabled={true} name="requiredDCPOther" value={formThree.requiredDCPOther} />}
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p className='required'>Will your DCP or ProRes include an Audio Descriptive track for Blind and low vision viewers? We encourage but do not require Audio Descriptive tracks at this time.</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={true} name="captionAudioInDCP" value={formThree.captionAudioInDCP} DefaultOption={'Select From Below'} options={state.stroboscopic} />
                                    {formThree.captionAudioInDCP === 'Yes' && <TextArea disabled={true} name="captionAudioInDCPOther" value={formThree.captionAudioInDCPOther} />}
                                </div>
                            </div>
                        </div>
                        <div className="project_info_form_inner_bottom">
                            {/* <div className="sd_flex">
                                <div className="left_content">
                                    <p>Would you like your Premiere screening to be an Open Caption screening?</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={true} name="premiereScreening" value={formThree.premiereScreening} DefaultOption={'Select From Below'} options={state.PremiereOpenCaption} />
                                    {formThree.premiereScreening === 'Yes' && <TextArea disabled={true} name="premiereScreeningOther" value={formThree.premiereScreeningOther} />}
                                </div>
                            </div>
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p>Would you like one of your Repeat screenings to be Open Caption?</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={true} name="repeatScreening" value={formThree.repeatScreening} DefaultOption={'Select From Below'} options={state.RepeatOpenCaption} />
                                    {formThree.repeatScreening === 'Yes' && <TextArea disabled={true} name="repeatScreeningOther" value={formThree.repeatScreeningOther} />}
                                </div>
                            </div>
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p>Would you like ALL of your screenings to be Open Caption?</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown disable={true} name="allOfYourScreening" value={formThree.allOfYourScreening} DefaultOption={'Select From Below'} options={state.allOfYourScreeningOther} />
                                    {formThree.allOfYourScreening === 'Yes, I would like all of our screenings to be Open Caption' && <TextArea disabled={true} name="allOfYourScreeningOther" value={formThree.allOfYourScreeningOther} />}
                                </div>
                            </div> */}
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p>Does your project include stroboscopic images or extensive light flickering that lasts longer than 5 seconds?</p>
                                    <span className="sd_textuppercase">(We use this information to alert viewers who are sensitive.)</span>
                                </div>
                                <div className="right_content">
                                    <Dropdown disable={true}  name="includeStroboscopicImg" value={formThree.includeStroboscopicImg} options={state.stroboscopic} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                            <div className="sd_flex sd_aligncenter">
                                <div className="left_content">
                                    <p>If yes, provide timecode(s) where it occurs in project:</p>
                                </div>
                                <div className="right_content">
                                    <TextArea disabled={true} name="timecodeOccursInProject" value={formThree.timecodeOccursInProject}  />
                                </div>
                            </div>
                            <div className='ai_question'>
                                <p className='pb_15'>Sundance Institute is collecting data on whether Artificial Intelligence (AI) is being utilized in the filmmaking process. This information is for internal data collection purposes only. Your answers have no impact on whether, when, or where your film is screened.</p>
                                <div className="sd_flex">
                                    <div className="left_content">
                                        <p className='required'>Did you use AI in any aspect of your project?</p>
                                    </div>
                                    <div className="right_content">
                                        <Dropdown require={true} disable={true} name="aiAspect" value={formThree.aiAspect} DefaultOption={'Select From Below'}  options={state.aiAspect} />
                                    </div>
                                </div>
                                <div className="sd_flex">
                                    <div className="left_content">
                                        <p className={`${formThree.aiAspect === 'Yes' ? 'required' : null}`}>If yes, please share what tools were used and how they were used in the making of the project.</p>
                                    </div>
                                    <div className="right_content">
                                        <TextArea disabled={true} name="aiAspectOther" value={formThree.aiAspectOther} />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="digital_media_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Digital Media <span className="sd_textuppercase">*Please do not include personal or company pages/accounts</span></h3>
                        <p>When you provide this information, the Sundance Film Festival will consider it public and may tag our posts with this information.</p>
                    </div>
                    <div className="digital_media_form_inner">
                        <TextField disabled={true} type="url"   name="officialWebsite" value={digitalMedia.officialWebsite}  placeholder="" uppercase={false} label="Official Website" required="false" />
                        <TextField disabled={true} type="url"   name="youtubeOrVimeoTrailer" value={digitalMedia.youtubeOrVimeoTrailer}   placeholder="" uppercase={false} label="YouTube or Vimeo" required="false" />
                        <TextField disabled={true} type="text"  name="instagram" value={digitalMedia.instagram}   placeholder=""uppercase={false} label="Instagram" required="false" />
                        <TextField disabled={true} type="url"   name="facebook" value={digitalMedia.facebook}   placeholder="" uppercase={false} label="Facebook" required="false" />
                        <TextField disabled={true} type="text"  name="twitter" value={digitalMedia.twitter}   placeholder="" uppercase={false} label='X (Formerly "Twitter")' required="false" />
                        <TextField disabled={true} type="text" name="tiktok" value={digitalMedia.tiktok} placeholder="" uppercase={false} label="Tiktok" required="false" />
                        <TextField disabled={true} type="text"  name="hastag" value={digitalMedia.hastag}   placeholder=""uppercase={false} label="Hashtag" required="false" />

                    </div>
                </div>
                <PreviewFormFooter step={3} rootId='downloadStep3'/> 
            </div>
            <Footer />
        </div>
    )
}

export default  Step3;                          