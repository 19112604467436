import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import service from "../common/service";
import moment from "moment";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let User = cookies.get("PAFUser");

// Components
// import Newsletter from "../components/Newsletter";

const mapStateToProps = state => ({
  menuEnbleDate:state.menuEnbleDate
});
class Footer extends Component {

constructor(props) {
    super(props);
    this.apiServe = new service();
}

//   gotoPage(e,page){
//      this.props.history.push(page); 
//    // window.open(process.env.REACT_APP_HOME_OLD_URL + page)
//   }

gotoPage(to) {
    if (to === "schedule") {
      window.open( process.env.REACT_APP_HEADER_PROGRAM + `schedule/`, "_self" );
    } else if (to === "new-frontier") {
      window.open( process.env.REACT_APP_HEADER_PROGRAM + `new-frontier/`, "_self" );
    } else if (to === "beyond-films") {
      window.open( process.env.REACT_APP_HEADER_PROGRAM + `beyond-films/`, "_self" );
    } else if (to === "live-events") {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `live-events`, "_self");
    } else if (to === "partner-events") {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `partner-events/`, "_self");
    } else if(to === 'highlights'){
      window.open(process.env.REACT_APP_HOME_URL + 'highlight' ,'_self')
    } else if(to === 'about'){
      window.open(process.env.REACT_APP_HOME_URL + 'about' ,'_self')
    } else if(to === 'community-agreement'){
      window.open(process.env.REACT_APP_HOME_URL + 'community-agreement' ,'_self')
    } else {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `films/`, "_self");
    }
  }
  gotoNewTab(e, link) {
    e.preventDefault();
    window.open(link);
}
  goto(Link) {
    window.open(Link, "_self")
  }
    logout = () => {
    if (User && User.token) {
        this.props.dispatch({ type: 'USER', payload: {} })
        // eslint-disable-next-line
        document.cookie = "PAFUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie = "PAFProjectId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

        cookies.remove("PAFUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
        cookies.remove("PAFProjectId", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });

        let Zone = localStorage.getItem('TIMEZONE')
        localStorage.clear();
        if (Zone) localStorage.setItem('TIMEZONE', Zone)
        // window.open(process.env.REACT_APP_HOME_URL + 'sign-in', "_self")
    }
};
  render() {
    let particularUser = cookies.get("privateScreeningUser") ||cookies.get("SundanceNewUser") || cookies.get("particularNewUser") || cookies.get("STYXKEY_sundanceUser");
    let userInfo = cookies.get("STYXKEY_sundanceUser")
    const menuEnableDates = localStorage.getItem('MENUE_ENABLE_DATE') ? JSON.parse(localStorage.getItem('MENUE_ENABLE_DATE')) : this.props.menuEnbleDate
    return (
      <footer>
        {/* Newsletter */}
        {/* <Newsletter /> */}

        {/* Footer */}
        <div className="sd_large_footer">
            <div className="sd_footer_links_wpr">
                <div className="sd_large_container sd_footer_container sd_container_1440">
                <div className="sd_footer_links_content sd_flex sd_desktop_footer">
                    <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Top Links
                        </label>
                        <ul className={`sd_links_list`} id="helpFullLink">
                          {moment().isBetween(menuEnableDates['SHOP_MERCH']?.startDate, menuEnableDates['SHOP_MERCH']?.endDate) && <li>
                            <span onClick={(e) => this.gotoNewTab(e, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}>Merch </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['HIGHLIGHT']?.startDate, menuEnableDates['HIGHLIGHT']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HOME_URL + 'highlights/')}> Highlights </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['MAP']?.startDate, menuEnableDates['MAP']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_MAP)}> Map </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['VENUE']?.startDate, menuEnableDates['VENUE']?.endDate) && <li><span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_VENUES_EXPERIENCE)}>Venues</span></li>}
                          {moment().isBetween(menuEnableDates['LODGING']?.startDate, menuEnableDates['LODGING']?.endDate) && <li><span onClick={(e) => this.gotoNewTab(e,process.env.REACT_APP_LODGING_URL + 'lodging/')}>Lodging</span></li>}
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e,process.env.REACT_APP_INDUSTRY_URL)}> Sundance Industry Office </span>
                          </li>
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e,process.env.REACT_APP_PRESS_URL)}> Press </span>
                          </li>
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e, 'https://www.sundance.org/customersupport')}> Customer Support </span>
                          </li>
                        </ul>
                        </div>
                    </div>
                    {moment().isBetween(menuEnableDates['FPG']?.startDate, menuEnableDates['FPG']?.endDate) && <div className="sd_footer_links">
                        <div>
                        <div className="sd_flex sd_aligncenter">
                        <label className={`sd_flex sd_aligncenter`}>
                          Program
                        </label>
                        </div>
                        <ul className={`sd_links_list`} id='programLink'>
                            {moment().isBetween(menuEnableDates['FPG_FILMS']?.startDate, menuEnableDates['FPG_FILMS']?.endDate) && <li>
                            <span onClick={this.gotoPage.bind(this, 'films')}>Films</span>
                            </li>}
                            {moment().isBetween(menuEnableDates['FPG_BEYOND_FILMS']?.startDate, menuEnableDates['FPG_BEYOND_FILMS']?.endDate) && <li>
                            <span onClick={this.gotoPage.bind(this, 'beyond-films')} >Beyond Film</span>
                            </li>}
                            {moment().isBetween(menuEnableDates['FPG_PARTNER_EVENTS']?.startDate, menuEnableDates['FPG_PARTNER_EVENTS']?.endDate) && <li>
                              <span onClick={this.gotoPage.bind(this, 'partner-events')} >Partner Events</span>
                            </li>}
                            {moment().isBetween(menuEnableDates['FPG_SCHEDULE']?.startDate, menuEnableDates['FPG_SCHEDULE']?.endDate) && <li><span onClick={this.gotoPage.bind(this, 'schedule')}>Schedule</span></li>}
                        </ul>
                        </div>
                    </div>}
                    {moment().isBetween(menuEnableDates['TICKETING']?.startDate, menuEnableDates['TICKETING']?.endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Tickets</label>
                        
                        <ul className={`sd_links_list`} id="ticketLink">
                          {moment().isBetween(menuEnableDates['TICKETING_INPERSON']?.startDate, menuEnableDates['TICKETING_INPERSON']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'in-person')}> In Person </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['TICKETING_ONLINE']?.startDate, menuEnableDates['TICKETING_ONLINE']?.endDate) && <li>
                          <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'online')}>Online</span>
                          </li>}
                          {moment().isBetween(menuEnableDates['TICKETING_COMPARE']?.startDate, menuEnableDates['TICKETING_COMPARE']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'compare-ticket')}> Compare Tickets </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['TICKETING_FAQ']?.startDate, menuEnableDates['TICKETING_FAQ']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'faq')}>FAQ</span>
                          </li>}
                        </ul>
                        </div>
                    </div>}
                    {moment().isBetween(menuEnableDates['HOW_TO_FEST']?.startDate, menuEnableDates['HOW_TO_FEST']?.endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        How to Fest</label>
                        
                        <ul className={`sd_links_list`} id="howToFestLink">
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/at-a-glance')}>  At a Glance </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/ticketing')}> Ticketing </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/attend-in-person')}>Attend in Person</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/watch-online')}>Watch Online</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/accessibility')}> Accessibility </span>
                          </li>
                        </ul>
    
                        </div>
                    </div>}
                </div>
                <div className="sd_footer_links_content sd_flex sd_mobile_footer">
                  <div>
                    <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Top Links
                        </label>
                        <ul className={`sd_links_list`} id="helpFullLink">
                          {moment().isBetween(menuEnableDates['SHOP_MERCH']?.startDate, menuEnableDates['SHOP_MERCH']?.endDate) && <li>
                            <span onClick={(e) => this.gotoNewTab(e, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}>Merch </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['HIGHLIGHT']?.startDate, menuEnableDates['HIGHLIGHT']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HOME_URL + 'highlights/')}> Highlights </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['MAP']?.startDate, menuEnableDates['MAP']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_MAP)}> Map </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['VENUE']?.startDate, menuEnableDates['VENUE']?.endDate) && <li><span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_VENUES_EXPERIENCE)}>Venues</span></li>}
                          {moment().isBetween(menuEnableDates['LODGING']?.startDate, menuEnableDates['LODGING']?.endDate) && <li><span onClick={(e) => this.gotoNewTab(e,process.env.REACT_APP_LODGING_URL + 'lodging/')}>Lodging</span></li>}
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e,process.env.REACT_APP_INDUSTRY_URL)}> Sundance Industry Office </span>
                          </li>
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e,process.env.REACT_APP_PRESS_URL)}> Press </span>
                          </li>
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e, 'https://www.sundance.org/customersupport')}> Customer Support </span>
                          </li>
                        </ul>
                        </div>
                    </div>
                    {moment().isBetween(menuEnableDates['HOW_TO_FEST']?.startDate, menuEnableDates['HOW_TO_FEST']?.endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        How to Fest</label>
                        
                        <ul className={`sd_links_list`} id="howToFestLink">
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/at-a-glance')}>  At a Glance </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/ticketing')}> Ticketing </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/attend-in-person')}>Attend in Person</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/watch-online')}>Watch Online</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/accessibility')}> Accessibility </span>
                          </li>
                        </ul>
    
                        </div>
                    </div>}
                  </div>
                  <div>
                    {moment().isBetween(menuEnableDates['FPG']?.startDate, menuEnableDates['FPG']?.endDate) && <div className="sd_footer_links">
                        <div>
                        <div className="sd_flex sd_aligncenter">
                        <label className={`sd_flex sd_aligncenter`}>
                          Program
                        </label>
                        </div>
                        <ul className={`sd_links_list`} id='programLink'>
                            {moment().isBetween(menuEnableDates['FPG_FILMS']?.startDate, menuEnableDates['FPG_FILMS']?.endDate) && <li>
                            <span onClick={this.gotoPage.bind(this, 'films')}>Films</span>
                            </li>}
                            {moment().isBetween(menuEnableDates['FPG_BEYOND_FILMS']?.startDate, menuEnableDates['FPG_BEYOND_FILMS']?.endDate) && <li>
                            <span onClick={this.gotoPage.bind(this, 'beyond-films')} >Beyond Film</span>
                            </li>}
                            {moment().isBetween(menuEnableDates['FPG_PARTNER_EVENTS']?.startDate, menuEnableDates['FPG_PARTNER_EVENTS']?.endDate) && <li>
                              <span onClick={this.gotoPage.bind(this, 'partner-events')} >Partner Events</span>
                            </li>}
                            {moment().isBetween(menuEnableDates['FPG_SCHEDULE']?.startDate, menuEnableDates['FPG_SCHEDULE']?.endDate) && <li><span onClick={this.gotoPage.bind(this, 'schedule')}>Schedule</span></li>}
                        </ul>
                        </div>
                    </div>}
                    {moment().isBetween(menuEnableDates['TICKETING']?.startDate, menuEnableDates['TICKETING']?.endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Tickets</label>
                        
                        <ul className={`sd_links_list`} id="ticketLink">
                          {moment().isBetween(menuEnableDates['TICKETING_INPERSON']?.startDate, menuEnableDates['TICKETING_INPERSON']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'in-person')}> In Person </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['TICKETING_ONLINE']?.startDate, menuEnableDates['TICKETING_ONLINE']?.endDate) && <li>
                          <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'online')}>Online</span>
                          </li>}
                          {moment().isBetween(menuEnableDates['TICKETING_COMPARE']?.startDate, menuEnableDates['TICKETING_COMPARE']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'compare-ticket')}> Compare Tickets </span>
                          </li>}
                          {moment().isBetween(menuEnableDates['TICKETING_FAQ']?.startDate, menuEnableDates['TICKETING_FAQ']?.endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'faq')}>FAQ</span>
                          </li>}
                        </ul>
                        </div>
                    </div>}
                  </div>
                </div>
                </div>
            </div>
            <div className="sd_footer_links_wpr">
            <div className="">
                <div className="sd_footer_wpr sd_flex sd_aligncenter sd_justbetween">
                    <div className="sd_flex sd_aligncenter sd_flex_1_auto sd_justbetween">
                        <div className="sd_footer_logo">
                            <div className="sd_footer_logos sd_aligncenter">
                            <div className="sd_footer_main_logo">
                            <Link onClick={this.goto.bind(this, process.env.REACT_APP_HOME_OLD_URL)} rel="noopener noreferrer" >
                                <img src={'/images/footer_logo.svg'} alt="Sundance Institute" title="Sundance Institute" />
                            </Link>
                            {/* <Link onClick={this.goto.bind(this, process.env.REACT_APP_HOME_OLD_URL)} rel="noopener noreferrer" className="footer_webby_logo">
                                <img src='/images/footer_webby_honoree.png' alt="Sundance Institute" title="Sundance Institute" />
                            </Link> */}
                            </div>
                        </div>
                            <p className="sd_textuppercase">
                            {" "}
                            Copyright &copy; {new Date().getFullYear()} Sundance Institute,
                            All Rights Reserved{" "}
                        </p>
                        </div>
                        <div className="sd_footer_navbar">  
                            <ul>
                                <li>
                                    {/* <a href="https://digital.sundance.org/#about" rel="noreferrer" target="_blank"> About Sundance Institute </a> */}
                                    <a href="https://www.sundance.org/about/us" target="_blank" > About Sundance Institute </a>
                                    {/* <Link onClick={(e)=>this.gotoPage(e,"about")} > About Sundance Institute </Link> */}
                                </li>
                                {/* <li>
                                <a href="https://digital.sundance.org/#help" rel="noreferrer" target="_blank"> Help </a>
                                    <Link onClick={(e)=>this.gotoPage(e,"help")}> Help </Link>
                                </li> */}
                                <li>
                                  <Link onClick={this.gotoPage.bind(this,"community-agreement")}>  Community Agreement</Link>
                                </li>
                            </ul>
                        </div>
                    </div>  
                    <div className="sd_footer_navigation_bar">
                    <div className="sd_footer_nav">
                    <ul className="sd_flex sd_aligncenter">
                        <li>
                        <a
                            href="https://collab.sundance.org/"
                            target="_blank"
                            rel="noreferrer" className="sd_collab_logo"
                        >
                            <img
                            src={'/images/collab_logo.svg'}
                            alt="Sundance Collab"
                            title="Sundance Collab"
                            />
                        </a>
                        </li>
                        <li>
                        <ul className="sd_flex sd_aligncenter">
                            <li>
                            <a
                                href="https://www.instagram.com/sundanceorg/"
                                target="_blank"
                                rel="noreferrer"
                            >  
                             <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 448 512" ><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="white" /></svg>
                            </a>
                            </li>
                            <li className="sff_tiktok_icon">
                            <a
                                href="https://www.tiktok.com/@sundanceorg/"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21" height="21"><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" fill="white"/></svg>
                            </a>
                            </li>
                             <li>
                            <a
                                href="https://www.youtube.com/c/sundancefilmfestival"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="24" height="24"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" fill="white" /></svg>
                            </a>
                            </li>
                            <li>
                            <a
                                href="https://www.facebook.com/sundance"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="21" height="21"><path d="M279.1 288l14.2-92.7h-88.9v-60.1c0-25.4 12.4-50.1 52.2-50.1h40.4V6.3S260.4 0 225.4 0c-73.2 0-121.1 44.4-121.1 124.7v70.6H22.9V288h81.4v224h100.2V288z" fill="white" /></svg>
                            </a>
                            </li>
                            <li>
                            <a
                                href="https://twitter.com/sundancefest"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                src="/images/sff_twitter_icon.svg"
                                alt="Twitter"
                                title="Twitter"
                                />
                            </a>
                            </li>
                            <li>
                            <a
                                href="https://threads.net/@sundanceorg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                src="/images/sd_thread_icon.svg"
                                alt="thread"
                                title="thread"
                                />
                            </a>
                            </li>
                           
                        </ul>
                        </li>
                    </ul>
                    <ul className="sd_flex sd_aligncenter">
                        <li>
                        <a
                            href="https://www.sundance.org/about/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        </li>
                        <li>
                        <a
                            href="https://www.sundance.org/about/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms &amp; Conditions 
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                    <div className="sd_footer_copyright sd_hidden">
                    <p className="sd_textuppercase">
                        {" "}
                        Copyright &copy; {new Date().getFullYear()} Sundance Institute,
                        All Rights Reserved
                    </p>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </footer>
    );
  }
}
export default connect(mapStateToProps, null)(withRouter(Footer)) ;