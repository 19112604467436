import { REHYDRATE } from 'redux-persist';

let initState = {
    token: '',
    User: '',
    ProjectInfo: {},
    projectGetDetail: {},
    SharedProjectInfo: {},
    SharedGetDetail:{},
    currentPage: '',
    samePage: false,
    pafType:'',
    SharedPafType:'',
    refcode:'',
    menuEnbleDate:{},
};

function sundanceReducer(state = initState, action) {
    switch (action.type) {
        case 'AUTHENTICATION': {
            return { ...state, ...action.payload.user, token: action.payload.token }
        }
        case 'USER': {
            return { ...state, User: action.payload }
        }
        case 'FORM_DATA': {
            return { ...state, ProjectInfo: action.payload }
        }
        case 'PROJECT_GET_DETAILS': {
            return { ...state, projectGetDetail: action.payload }
        }
        case 'SHARED_GET_DETAILS': {
            return { ...state, SharedGetDetail: action.payload }
        }
        case 'SET_CURRENT_PAGE': {
            return { ...state, currentPage: action.payload }
        }
        case 'SHARED_PROJECT_DATA': {
            return { ...state, SharedProjectInfo: action.payload }
        }
        case 'CHECK_SAME_PAGE1': {
            return { ...state, samePage: action.payload }
        }
        case 'PAF_TYPE': {
            return { ...state, pafType: action.payload }
        }
        case 'SHARED_PAF_TYPE': {
            return { ...state, SharedPafType: action.payload }
        }
        case 'REF_CODE': {
            return { ...state, refcode: action.payload }
        }
        case 'MENUE_ENABLE_DATE':{
            return { ...state, menuEnbleDate: action.res };
        }
        case REHYDRATE:
            return {
                ...state, User: action?.payload?.User ? action?.payload?.User : '',
                ProjectInfo: action?.payload?.ProjectInfo ? action?.payload?.ProjectInfo : '',
                projectGetDetail: action?.payload?.projectGetDetail ? action?.payload?.projectGetDetail : {},
                currentPage: action?.payload?.currentPage ? action?.payload?.currentPage : '',
                pafType: action?.payload?.pafType ? action?.payload?.pafType : '',
                refcode: action?.payload?.refcode ? action?.payload?.refcode : '',
                menuEnbleDate: action.res && action.res.menuEnbleDate ? action.res.menuEnbleDate : {},
            }
        default:
            return { ...state };
    }
}

export const reducer = sundanceReducer;
